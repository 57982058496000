import React, { FunctionComponent } from "react";
import "./styles.scss";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { ImageInterface } from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ColorVariations } from "../../types/ComponentTypes";

export interface PageTitleInterface {
  title: ReadonlyArray<string>;
  image: ImageInterface;
  color: string;
}

const PageTitle: FunctionComponent<PageTitleInterface> = ({ title, image, color }) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const colorVariations: ColorVariations = {
    Purple: {
      primaryGradientColor: "var(--purple-light-color)",
      secondaryGradientColor: "var(--pink-light-color)",
      gradientDegrees: "10"
    },
    Red: {
      primaryGradientColor: "var(--orange-color)",
      secondaryGradientColor: "var(--pink-medium-color)",
      gradientDegrees: "300"
    },
    Teal: {
      primaryGradientColor: "var(--get-mentored-green-color)",
      secondaryGradientColor: "var(--get-mentored-blue-color)",
      gradientDegrees: "225"
    },
    Pink: {
      primaryGradientColor: "var(--pink-bright-color)",
      secondaryGradientColor: "var(--pink-bright-color)",
      gradientDegrees: "-146"
    },
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Primary: {
      primaryGradientColor: "var(--primary-color)",
      secondaryGradientColor: "var(--primary-color)",
      gradientDegrees: "90"
    },
    "Light Blue": {
      primaryGradientColor: "var(--yellow-color)",
      secondaryGradientColor: "var(--blue-light-color)",
      gradientDegrees: "135"
    },
    "Light Green": {
      primaryGradientColor: "var(--purple-color)",
      secondaryGradientColor: "var(--green-light-color)",
      gradientDegrees: "230"
    },
    Rose: {
      primaryGradientColor: "var(--purple-light-color)",
      secondaryGradientColor: "var(--pink-light-color)",
      gradientDegrees: "75"
    }
  };

  const colorGradient = `linear-gradient(${colorVariations[color].gradientDegrees}deg, ${colorVariations[color].primaryGradientColor} 0%, ${colorVariations[color].secondaryGradientColor} 100%)`;

  const heading = () => {
    return (
      <h1 className="header-title" key={title.join("")}>
        {title.map((item, i) => {
          if (i % 2) {
            return <span key={i}>{title[i]}</span>;
          } else {
            if (item.length >= 2 && item.length <= 5) {
              return <div key={i}>{title[i]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>;
            } else {
              return <div key={i}>{title[i]}</div>;
            }
          }
        })}
      </h1>
    );
  };

  return (
    <div className="page-title" data-testid="page-title">
      <div className="img-position" style={{ background: colorGradient }}>
        {
          <picture>
            <source
              media={"(min-width: 1200px)"}
              srcSet={
                urlBuilder.image(image).auto("format").quality(80).width(800).height(645).format("webp").url() ||
                undefined
              }
            />
            <source
              media={"(min-width: 768px)"}
              srcSet={
                urlBuilder.image(image).auto("format").quality(80).width(600).height(545).format("webp").url() ||
                undefined
              }
            />
            <img
              className="img-fluid"
              src={
                urlBuilder.image(image).auto("format").quality(80).width(500).height(345).format("webp").url() ||
                undefined
              }
              alt={image.alt}
            />
          </picture>
        }
      </div>
      {heading()}
    </div>
  );
};

export default PageTitle;
