import * as React from "react";
import Layout from "../../components/Layout";
import Helmet from "react-helmet";
import ContactUsForm from "../../components/ContactUsForm";
import { graphql, PageProps } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PageTitle from "../../components/PageTitle";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import "./styles.scss";
import {
  ImageInterface,
  LocalizedContextInterface,
  MultiLineHeadline,
  SanityColorList,
  Seo,
  Slug
} from "../../types/SanityTypes";
import { LocalisedPageContext } from "../../types/PageTypes";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";

type EmailPageQueryProps = {
  sanityEmailPage: {
    banner: {
      headline: MultiLineHeadline;
      _rawHeroImage: ImageInterface;
      primaryColor: SanityColorList;
    };
    seo: Seo;
  };
  sanityContactUs: Slug & {
    _id: string;
  };
} & LocalizedContextInterface;

type EmailPagePageContext = LocalisedPageContext;

export const query = graphql`
  query EmailPage($_id: String, $language: String, $market: String) {
    sanityEmailPage(_id: { eq: $_id }) {
      banner {
        headline {
          primaryText
          secondaryText
          tertiaryText
        }
        _rawHeroImage(resolveReferences: { maxDepth: 8 })
        primaryColor {
          title
          value
        }
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
    }
    sanityContactUs(i18n_lang: { eq: $language }) {
      _id
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

const EmailPage = (props: PageProps<EmailPageQueryProps, EmailPagePageContext>) => {
  const language = props.pageContext.language;
  const { contactUsBrandId, contactUsEnv, contactUsFormType, contactUsLocale, siteUrl } = useSiteMetadata(language);
  const emailPage = props.data.sanityEmailPage;
  const localizedPath = withPrefix(
    getLocalizedPath(props.data?.sanityContactUs?.slug?.current, props.data?.sanityContactUs?._id)
  );

  // This is to make sure banner works of internationalisation
  const lineOne =
    emailPage.banner.headline.primaryText.length <= 5
      ? emailPage.banner.headline.primaryText
      : emailPage.banner.headline.primaryText + " ";
  const lineTwo =
    emailPage.banner.headline.secondaryText === null ? " " : emailPage.banner.headline.secondaryText + " ";
  const lineThree =
    emailPage.banner.headline.tertiaryText === null ? " " : emailPage.banner.headline.tertiaryText + " ";

  const pageTitle = lineOne + lineTwo + lineThree;

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={pageTitle} pageType={"contact"}>
      <StructuredData customData={emailPage.seo.jsonld} />
      <SEO
        title={emailPage.seo.metaTitle || pageTitle}
        description={emailPage.seo.metaDescription}
        imageUrl={emailPage.banner._rawHeroImage.asset.url}
        imageAlt={emailPage.banner._rawHeroImage.alt}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <div className="page_get-in-touch">
        <Container fluid data-testid="get-in-touch" className="theme_blue inner">
          <Row className="header-type-1">
            <Col>
              <PageTitle
                title={[lineOne, lineTwo, lineThree]}
                image={emailPage.banner._rawHeroImage}
                color={emailPage.banner.primaryColor.title}
              />
            </Col>
          </Row>
        </Container>
        <ContactUsForm
          env={contactUsEnv}
          locale={contactUsLocale}
          brandId={contactUsBrandId}
          formType={contactUsFormType}
        />
      </div>
    </Layout>
  );
};

export default EmailPage;
